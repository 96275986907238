<template>
  <error-page-layout
    :icon="'alert'"
    :title="'Internal Server Error'"
  >
    <template #content>
      <b-image
        :src="InternalServerErrorImage"
        alt="Internal Server Error"
        ratio="6by4"
        :rounded="false"
      />

      <div class="level">
        <div class="level-item">
          <div class="content has-text-centered">
            <b-button
              type="is-primary"
              tag="router-link"
              to="/organization"
              icon-left="chevron-left"
            >
              go back home
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </error-page-layout>
</template>

<script>
import InternalServerErrorImage from '@/assets/images/internal-server-error.svg';

export default {

  name: 'InternalServerError',

  components: {
    ErrorPageLayout: () => import('../../layouts/ErrorPageLayout.vue'),
  },

  data: () => ({
    InternalServerErrorImage,
  }),

};
</script>

<style>

</style>
