<template>
  <error-page-layout
    :icon="'alert'"
    :title="'Unauthorized'"
  >
    <template #content>
      <figure class="image is-16by9">
        <img
          src="@/assets/images/unauthorized.svg"
          alt="Page Not Found"
        >
      </figure>
      <div class="level">
        <div class="level-item has-text-centered">
          <div class="content">
            <h2 class="title has-text-primary is-capitalized">
              not authorized
            </h2>
            <p>
              Access is allowed only for registered users
            </p>
            <b-button
              tag="router-link"
              to="/"
              type="is-primary"
              aria-label="Login"
            >
              Go To Login Page
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </error-page-layout>
</template>

<script>
export default {
  name: 'Unauthorized',

  components: {
    ErrorPageLayout: () => import('../../layouts/ErrorPageLayout.vue'),
  },
};
</script>

<style>

</style>
