<template>
  <error-page-layout
    :icon="'alert'"
    :title="'Not Found'"
  >
    <template #content>
      <figure class="image is-16by9">
        <img
          src="@/assets/images/page-not-found.svg"
          alt="Page Not Found"
        >
      </figure>
      <div class="level">
        <div class="level-item has-text-centered">
          <div>
            <p>
              The resource requested could not be found!
            </p>

            <b-button
              tag="router-link"
              to="/organization"
              type="is-primary"
              icon-left="chevron-left"
            >
              go back home
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </error-page-layout>
</template>

<script>
export default {
  name: 'PageNotFound',

  components: {
    ErrorPageLayout: () => import('../../layouts/ErrorPageLayout.vue'),
  },
};
</script>

<style>

</style>
