<template>
  <error-page-layout
    :icon="'alert'"
    :title="'Forbbiden'"
  >
    <template #content>
      <b-image
        :src="ForbiddenImage"
        alt="Forbidden"
        ratio="6by4"
        :rounded="false"
      />

      <div class="level">
        <div class="level-item">
          <div class="content has-text-centered">
            <h3 class="subtitle">
              Forbidden Access
            </h3>
            <p>
              You don't have the neccessary permissions to access the page
              <br>please contact your business admin
            </p>
            <b-button
              type="is-primary"
              tag="router-link"
              to="/profile"
              icon-left="chevron-left"
            >
              go back home
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </error-page-layout>
</template>

<script>
import ForbiddenImage from '@/assets/images/forbidden.svg';

export default {

  name: 'Forbidden',

  components: {
    ErrorPageLayout: () => import('../../layouts/ErrorPageLayout.vue'),
  },

  data: () => ({
    ForbiddenImage,
  }),

};
</script>

<style>

</style>
